/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link, Quote } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/header-wiredup-performance-management.jpg";
import commaimg from "../../images/Quotation_mark.png";
import uparrow from "../../images/top-button.svg";
import groupmeeting_section from "../../images/wiredup-performance-group-meetings.png";
import groupmeeting_icon from "../../images/Group-meetings-icon.png";
import Individual_reviews_section from "../../images/wiredup-performance-individual-review.png";
import Individual_reviews_icon from "../../images/Individual-reviews-icon.png";
import leftIcon from "../../images/Left Button.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};
const groupmeeting_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { groupmeeting_section },
      srcSet: groupmeeting_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const Individual_reviews_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Individual_reviews_section },
      srcSet: Individual_reviews_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const comma = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { commaimg },
      srcSet: commaimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 600,
  height: 600,
};
const persondetails = {
  company: null,
  country: "Australia and New Zealand",
  image: comma,
  jobTitle: "Maintenance Manager",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "Mining Services",
  quote:
    "WiredUp enabled meaningful management meetings. It allowed each area manager to talk about their successes, challenges, disappointments and emerging issues, and empowered them to ask our project manager what things we needed help with.",
  sector: "Infrastructure",
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={"performance management tools, business performance management"}
      title={" Business Performance Management Tools by WiredUp"}
      description={
        "WiredUp’s proven performance management tools help you create alignment and foster commitment & accountability across your organisation."
      }
    >
      <Hero
        title="Business performance management"
        subheading=""
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-2 grid-cols-1 p-10px page-grid row md:w-mobile-6-cols w-auto m-auto">
        <a
          href="#individual_reviews"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Individual_reviews_icon}
                  alt="optimise-organisational-efficiency"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Individual reviews</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Identify and address issues earlier by managing reporting up and
                down the line with one-to-one reviews that enhance
                communication, improve performance and increase engagement
              </p>
            </div>
          </div>
        </a>
        <a
          href="#group_meetings"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={groupmeeting_icon}
                  alt="schedule-group-meeting"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Group meetings</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Transform your team meetings with ease and drive their
                performance by designing cascading sessions that enhance
                clarity, focus and effectiveness
              </p>
            </div>
          </div>
        </a>
      </div>

      <Quote
        quote={`"WiredUp enabled meaningful management meetings. It allowed each area manager to talk about their successes, challenges, disappointments and emerging issues, and empowered them to ask our project manager what things we needed help with."`}
        profile={persondetails}
        withoutqote="YES"
      />
      <div id="individual_reviews">
        <Article
          side="right"
          id={""}
          image={Individual_reviews_section_img}
          alt={"optimise-organisational-efficiency"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Optimise your organisational efficiency with streamlined one-to-one reviews that foster effective communication, elevate performance, and boost engagement. WiredUp empowers you to proactively address challenges and seize opportunities by offering comprehensive tracking and reporting up and down the line. Our individual review meeting module offer:",
              heading: [],
            },
          ]}
          Articletitle="Foster continuous improvement with individual meetings"
          listing={[
            "	Streamlined one-on-one reviews to identify and address issues earlier ",
            "	Clear KPI charts provide valuable insights for informed decision-making",
            "	Actionable next steps ensure progress and accountability",
            "Idea pipelines to foster innovation and drive success",
            "Compliance reviews and audits for airtight operations",
            "Detailed reports for comprehensive visibility into individual performance",
          ]}
          afterlisting="Optimise your processes, drive success and foster a culture of continuous improvement in every person across your organisation."
        />
      </div>
      <div id="group_meetings">
        <Article
          side="left"
          id={""}
          image={groupmeeting_section_img}
          alt={"schedule-group-meeting"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Experience seamless meeting management by effortlessly designing and facilitating cascading meetings that bring clarity and focus to your teams. WiredUp lets you run meetings directly from the source of truth, so you won’t lose time creating extra material, searching through scattered notes or finding old email attachments. Our group meeting module allows you to:",
              heading: [],
            },
          ]}
          Articletitle="A single source of truth for group meetings "
          listing={[
            "Clearly define the purpose, payoff, process, preparation, and participants for each meeting",
            "Capture actions and decisions in real-time, ensuring accountability and progress",
            "Integrate relevant KPIs and reports for a comprehensive review of performance",
            "Stay up to date with a dynamic ideas pipeline, fuelling innovation and growth",
          ]}
          afterlisting="Take control of your group meetings and unleash your team’s full potential by streamlining collaboration and productivity."
        />
      </div>
      <div className="bg-white-1 p-35px">
        <center className="mt-20px">
          <h3 className="h3">Add-on module</h3>
          <br></br>
          <h5 className="h4">
            The following add-on features are available in this plan
          </h5>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1 p-30px">
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <img
                  src={groupmeeting_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <h4 className="h4">Value driver diagnostic</h4>
              </a>
            </div>
          </div>
          <div className="p-30px md:w-desktop-4-cols w-auto mt-20px">
            <p>
              Make WiredUp even more powerful and efficient with our value
              driver diagnostic add-on. It empowers you to model key process or
              your whole business with value driver trees to identify
              bottlenecks, inefficient operations and key value generators while
              allowing for sensitivity, attribution and variance analysis.
            </p>
            <a href="/en/wiredup-diagnostic-and-analysis/">
              <p className="block pt-40px read-more md:w-desktop-4-cols w-auto m-auto">
                Discover more
              </p>
            </a>
          </div>
        </div>
      </div>

      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup-change-management-software"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
        {/* <div className="float-button-arrow bg-no-repeat bg-28px min-h-28px w-30px h-30px"></div> */}
      </button>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
